<template>
  <b-card no-body>
    <b-card-body>
      <b-row>
        <b-col>
          <search :search-data="searchData" />
        </b-col>
        <b-col cols="auto">
          <b-button
            class="mt-2"
            variant="primary"
            to="/app/offers/customer-search"
          >
            <FeatherIcon icon="PlusIcon" /> Oluştur
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
    <offer-list
      :remove-data="removeData"
      :data-list="dataList"
      :fields="fields"
    />
    <b-card-footer>
      <b-pagination
        v-model="currentPage"
        :total-rows="dataCounts"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </b-card-footer>
  </b-card>
</template>
<script>
import {
  BRow, BCol, BCard, BPagination, BButton, BCardFooter, BCardBody,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Search from '@/views/Admin/Offers/elements/Search.vue'
import OfferList from '@/views/Admin/Offers/Index/OfferList.vue'

const tableName = 'offers'
export default {
  name: 'Index',
  components: {
    BRow,
    BCol,
    BCard,
    BPagination,
    BButton,
    BCardFooter,
    BCardBody,
    Search,
    OfferList,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      fields: [
        {
          key: 'onumber',
          label: 'TEKLİF NO',
        },
        {
          key: 'company',
          label: 'FİRMA ADI',
        },
        {
          key: 'offers_status',
          label: 'DURUM',
        },
        {
          key: 'control',
          label: '',
          tdClass: 'text-right',
          thStyle: { width: '180px' },
        },
      ],
      dataQuery: {
        select: [
          `${tableName}.id AS id`,
          'customers.company AS company',
          'offers.odate AS odate',
          'offers.vdate AS vdate',
          'offers.onumber AS onumber',
          'offer_statuses.title AS offers_status',
          'users.name AS username',
          'dealer_users.name AS dealer_user',
          'dealer_users.id_dealers AS id_dealers',
        ],
        where: {
          'offers.id_offer_statuses <': 3,
        },
        order_by: ['offers.id', 'DESC'],
        limit: 20,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['offers/dataList']
    },
    dataCounts() {
      return this.$store.getters['offers/dataCounts']
    },
    saveData() {
      return this.$store.getters['offers/dataSaveStatus']
    },
    search() {
      return this.$store.getters['offers/search']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * 20
      this.getDataList()
    },
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getDataList()
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    searchData() {
      if (this.search.keyword) {
        this.dataQuery.or_like = {
          'customers.company': this.search.keyword,
          'customers.phone': this.search.keyword,
        }
      }
      if (this.search.id_brands) {
        this.dataQuery.where = {
          'offers.id_brands': this.search.id_brands,
          'offers.id_offer_statuses <': 3,
        }
      } else {
        this.dataQuery.where = {
          'offers.id_offer_statuses <': 3,
        }
      }
      this.getDataList()
    },
    getDataList() {
      this.$store.dispatch('offers/getDataList', this.dataQuery)
    },
    removeData(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('offers/removeData', id)
          this.$swal({
            icon: 'success',
            title: this.$store.state.app.removeResultTitle,
            text: this.$store.state.app.removeResultMessage,
            confirmButtonText: this.$store.state.app.removeResultClose,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
